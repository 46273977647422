<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      
      <h4>
        {{
          isResetPassword
            ? "Reset Password"
            : Object.entries(this.data).length === 0
            ? "ADD NEW ITEM"
            : "UPDATE ITEM"
        }}
      </h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar
      class="scroll-area--data-list-add-new"
      :settings="settings"
    >
      <div class="p-6">
       
        <!-- NAME -->
        <label for="FullName">{{$t('FullName')}} </label>
        <vs-input
          :disabled="isResetPassword"
          v-model="data.ApplicationUser.FullName"
          class="mt-5 w-full"
          name="item-name"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('Name')">{{
          errors.first("Name")
        }}</span>

        <!-- Email -->
        <label for="Email">{{$t('Email')}} </label>

        <vs-input
        :disabled="isResetPassword"
          v-model="data.ApplicationUser.Email"
          class="mt-5 w-full"
          name="Email"
          type="Email"
          v-validate="'required|email|min:3'"
        />
        <span class="text-danger text-sm" v-show="errors.has('Email')">{{
          errors.first("Email")
        }}</span>

        <!-- PhoneNumber -->
        <label for="PhoneNumber">{{$t('PhoneNumber')}} </label>

        <vs-input
        :disabled="isResetPassword"
          v-model="data.ApplicationUser.PhoneNumber"
          class="mt-5 w-full"
          name="PhoneNumber"
          type="number"
        />

        <!-- user name -->
       
        <span class="text-danger text-sm" v-show="errors.has('username')">{{
          errors.first("username")
        }}</span>

        <!-- password -->
        <label for="Password">{{$t('Password')}} </label>

        <vs-input
          icon-pack="feather"
          label-placeholder="Password"
          icon-after="true"
          :type="passwordType"
          icon="icon icon-eye-off"
          @icon-click="showPassword()"
          v-model="Password"
          class="mt-5 w-full"
          @input="checkPassword"
          name="password"
          :disabled="Id != '' && !isResetPassword"
          v-validate="'required|min:8'"
        />
        <span class="text-danger text-sm" v-show="errors.has('password')">{{
          errors.first("password")
        }}</span>

        <ul class="ml-2 mt-3">
          <li v-bind:class="{ is_valid: contains_eight_characters }">
            8 Characters
          </li>
          <li v-bind:class="{ is_valid: contains_number }">Contains Number</li>
          <li v-bind:class="{ is_valid: contains_uppercase }">
            Contains Uppercase
          </li>
          <li v-bind:class="{ is_valid: contains_special_character }">
            Contains Special Character
          </li>
        </ul>

      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button
        v-if="isResetPassword"
        class="mr-6"
        @click="ResetPassword"
        :disabled="!valid_password"
        >{{$t('Save')}}</vs-button
      >
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
        >{{$t('Cancel')}}</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moduleUser from "@/store/user/moduleUser.js";

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    isResetPassword: {
      type: Boolean,
      required: false
    },
    data: {
      type: Object,
      default: () => {}
    }
  },

  watch: {
    
  },
  data() {
    return {
      passwordType: "password",
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,

      PhoneNumber: "",
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },

  computed: {
    
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    
  },
  methods: {
    showPassword() {
      if (this.passwordType == "text") this.passwordType = "password";
      else this.passwordType = "text";
    },
    checkPassword() {
      this.password_length = this.Password.length;
      const format = /[ !@#$%^&*()_+\-={};':"|,.<>?]/;

      if (this.password_length > 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.Password);
      this.contains_uppercase = /[A-Z]/.test(this.Password);
      this.contains_special_character = format.test(this.Password);

      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    },
    getUsers() {
      // this.$store.dispatch("instructorList/fetchDataListItemsByFilter"," ");
      //     this.$store.dispatch("traineeList/fetchDataListItemsByFilter"," ");
      //     this.$store.dispatch("customerList/fetchDataListItemsByFilter"," ");
    },

    initValues() {
      this.Password = "";
    },

    ResetPassword() {
      const obj = {
        userID: this.data.UserId,
        newPassword: this.Password
      };

      this.$store
        .dispatch("UserList/HardResetPassword", obj)
        .then(res => {
          this.$vs.notify({
            color: "sucess",
            time: 8000,
            title: "sucess",
            text: res.data.message
          });
          this.$emit("closeSidebar");
          this.initValues();
        })
        .catch(err => {
          this.$vs.notify({
            time: 8000,
            color: "danger",
            title: "error",
            text: err.data
          });
          console.error(err);
        });
    }
  },
  components: {
    VuePerfectScrollbar,
  },
  created(){
    if (!moduleUser.isRegistered) {
      this.$store.registerModule("UserList", moduleUser);
      moduleUser.isRegistered = true;
    }
  }
};
</script>

<style>
.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 50%;
}
li {
  margin-bottom: 8px;
  color: #525f7f;
  position: relative;
}

li:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
</style>
<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
